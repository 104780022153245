class Toast {
    constructor() {
    }

    show(text, type) {
        let div = document.createElement('div');
        div.classList.add('min-toast')
        div.innerHTML = type + ": " + text;
        document.body.appendChild(div)
        window.setTimeout(function () {
            document.body.removeChild(div)
        }, 2000)
    }

    error(text) {
        this.show(text, "error")
    }
}


export default new Toast();