<template>
    <div class="login-wrapper">
        <div class="pure-g">
            <div class="login-header pure-u-1">
                <img src="../assets/image/bintang.svg" alt="logo" class="logo"/>
            </div>
            <div class="pure-u-1" style="font-size: 12px; padding: 1em 0;">version:{{ version }}</div>
            <div class="login-body pure-u-1">
                <form class="pure-form pure-form-stacked">
                    <fieldset>
                        <label for="login-name" class="fs-2">Name</label>
                        <input id="login-name" type="text" required placeholder="name" v-model="form.username">
                        <label for="login-password" class="fs-2">Password</label>
                        <input id="login-password" type="password" required placeholder="password"
                               v-model="form.password">
                        <label for="stacked-remember" class="pure-checkbox">
                            <input type="checkbox" id="stacked-remember" v-model="form.remember"/> Remember me
                        </label>
                    </fieldset>
                </form>
            </div>
            <div class="login-footer pure-u-1">
                <button :class=buttonClass() @click="doLogin()">Login</button>
            </div>
        </div>
    </div>
</template>

<script>
import md5 from "js-md5";
import {AdminLogin} from "@/store/api/login";
import {API_STATUS_CODE, APP_VERSION} from "@/config/config";
import {SaveItem} from "@/utils/storage";

export default {
    name: "Login",
    data() {
        return {
            loading: false,
            form: {username: '', remember: true, password: ''},
            version: APP_VERSION,
        }
    },
    methods: {
        buttonClass() {
            return "pure-button pure-button-primary" + (this.loading ? " pure-button-loading" : "");
        },
        doLogin() {
            this.loading = true;
            AdminLogin({
                username: this.form.username,
                password: md5(this.form.password),
                remember: this.form.remember * 1,
            }).then((resp) => {
                if (resp.data.code === API_STATUS_CODE.SUCCESS && resp.data.data) {
                    SaveItem("_ROLE_", resp.data.data.role_index, new Date().getTime() + 7 * 86400 * 1000);
                    SaveItem("_XSRF_", resp.data.data.xsrf_token);
                    SaveItem("_USER_", resp.data.data.user_name);
                    if (resp.data.data.chrome_ios_cookie) {
                        SaveItem("_CRIOS_", resp.data.data.chrome_ios_cookie);
                    }
                    this.$router.push('/cpdash/summary/otp');
                }
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped lang="scss">
.pure-button-loading {
    cursor: progress;
    opacity: 0.72;
}

.login-wrapper {
    margin: 5em auto;
    width: 240px;
    padding: 1em;

    .login-header {
        padding: 1em 0;

        .logo {
            width: 90%;
        }
    }

    .login-body {
        label {
            margin-bottom: 0.5em;
        }

        input[type=text], input[type=password] {
            width: 100%;
            margin-bottom: 1em;
        }
    }

    .login-footer {
        text-align: right;
    }
}
</style>