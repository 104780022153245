import Vue from 'vue'
import VueRouter from 'vue-router'
import home from "@/views/Home";
import login from "@/views/Login";
import {GetItem} from "@/utils/storage";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login',
        name: 'Home',
        component: home,
        children: [
            {
                path: '/cpdash/dash',
                component: () => import('@/views/Dash')
            },
            {
                path: '/cpdash/summary/otp',
                component: () => import('@/views/summary/ReportOtp')
            },
            {
                path: '/cpdash/summary/sub',
                component: () => import('@/views/summary/ReportSub')
            },
            {
                path: '/cpdash/trx/sub/resport/mo',
                component: () => import('@/views/transaction/ReportSub')
            },
            {
                path: '/cpdash/trx/sub/resport/dn',
                component: () => import('@/views/transaction/ReportSubDN')
            },
            {
                path: '/cpdash/trx/otp/resport/mo',
                component: () => import('@/views/transaction/ReportOtp')
            }
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: login,
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

// 不检测权限的router
const whitelist = ['/login', '/', '/about'];
router.beforeEach(function (to, from, next) {
    console.log('[router.hook]', from, '=>', to)
    if (whitelist.indexOf(to.fullPath) >= 0) {
        next()
    } else {
        let savedRole = GetItem("_ROLE_");
        if (!savedRole) {
            next({path: '/login'})
            return
        }
        next();
    }
})

export default router
