import {STORAGE_KEY_PREFIX} from "@/config/config";

export let SaveItem = function (key, value, expireIn) {
    let fullKey = STORAGE_KEY_PREFIX + key;
    try {
        let data = {value: value};
        if (expireIn && expireIn * 1 > 0) {
            data.expire_in = expireIn;
        }
        window.localStorage.setItem(fullKey, JSON.stringify(data));
    } catch (e) {
        //
    }
};

export let GetItem = function (key) {
    let fullKey = STORAGE_KEY_PREFIX + key;
    try {
        let data = JSON.parse(window.localStorage.getItem(fullKey));
        if (data && data.expire_in > 0 && new Date().getTime() > data.expire_in) {
            return null;
        }
        return data.value;
    } catch (e) {
        //
    }
    return null;
};

export let ClearItem = function (key) {
    let fullKey = STORAGE_KEY_PREFIX + key;
    try {
        return window.localStorage.removeItem(fullKey);
    } catch (e) {
        //
    }
    return null;
}