<template>
    <div class="home">
        <div class="nav-left">
            <img src="../assets/image/bintang.white.svg" alt="img" class="left-logo"/>
            <div class="pure-menu custom-restricted-width">
                <div class="pure-menu custom-restricted-width">
                    <span class="pure-menu-heading">Summary</span>
                    <ul class="pure-menu-list">
<!--                        <li class="pure-menu-item">-->
<!--                            <router-link to="/cpdash/dash" class="pure-menu-link">Overview</router-link>-->
<!--                        </li>-->
                        <li class="pure-menu-item">
                            <router-link to="/cpdash/summary/otp" class="pure-menu-link">MO</router-link>
                        </li>
                        <li class="pure-menu-item">
                            <router-link to="/cpdash/summary/sub" class="pure-menu-link">Subscription</router-link>
                        </li>
                        <!--
                        <li class="pure-menu-heading">Transactions</li>
                        <li class="pure-menu-item">
                            <router-link to="/cpdash/trx/otp/resport/mo" class="pure-menu-link">OneTimePayment
                            </router-link>
                        </li>
                        <li class="pure-menu-item">
                            <router-link to="/cpdash/trx/sub/resport/mo" class="pure-menu-link">Subscription
                            </router-link>
                        </li>
                        <li class="pure-menu-item">
                            <router-link to="/cpdash/trx/sub/resport/dn" class="pure-menu-link">SubscriptionDN
                            </router-link>
                        </li>
                        -->
                    </ul>
                </div>
                <div class="pure-menu custom-restricted-width" style="position: absolute; bottom: 1em; width: 100%;">
                    <div class="pure-menu custom-restricted-width">
                        <span class="pure-menu-heading"></span>
                        <ul class="pure-menu-list">
                            <li class="pure-menu-item" style="cursor: pointer;">
                                <a class="pure-menu-link" @click="logout">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-right">
            <div class="main-body">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import {AdminLogout} from "@/store/api/login";
import {ClearItem} from "@/utils/storage";

export default {
    name: 'Home',
    components: {},
    methods: {
        logout() {
            AdminLogout().then(() => {
                ClearItem("_CRIOS_");
                ClearItem("_ROLE_");
                ClearItem("_XSRF_");
                ClearItem("_USER_");
            });
            this.$router.push('/login');
        }
    }
}
</script>

<style lang="scss" scoped>
.home {
    position: relative;
}

.left-logo {
    height: 36px;
    padding: 1em;
}

.nav-left {
    width: 220px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #191818;
    color: white;

    .pure-menu-heading:focus, .pure-menu-heading:hover, a {
        color: #999;
    }

    .pure-menu-list li .pure-menu-link:focus, .pure-menu-list li .pure-menu-link:hover {
        background: #333;
    }

    .router-link-active {
        background-color: #333;
    }
}


.main-right {
    padding-left: 220px;

    .main-body {
        padding: 1em;
    }
}
</style>
