import {API_STATUS_CODE} from "@/config/config";
import {GetItem} from "@/utils/storage";
import Toast from '@/components/global/Toast';

const axios = require('axios');

axios.defaults.timeout = 120000; // gateway default timeout time
axios.defaults.xsrfCookieName = "XSRF-TOKEN"
axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN"
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

axios.interceptors.request.use(function (config) {
    config.headers["X-XSRF-TOKEN"] = GetItem("_XSRF_")
    if (GetItem("_CRIOS_")) {
        config.headers["CRIOS-COOKIE"] = GetItem("_CRIOS_");
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    let isLogout = response.config.url.indexOf("/admin/do/logout") >= 0;
    if (response.data && response.data.code === API_STATUS_CODE.SUCCESS) {
        if (response.data.data && typeof (response.data.data) === "string") {
            response.data.data = JSON.parse(response.data.data)
        }
    } else {
        if (!isLogout) {
            Toast.error(response.data && response.data.data && response.data.data.msg || response.statusText || response.status)
        }
    }
    return response;
}, function (error) {
    console.log('instance.interceptors.response', 'error', error)
    // Toast.error(error);
    return Promise.reject(error);
});

export default axios;